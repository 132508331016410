import { useEffect, useState } from "react";
import { Routes, Route } from "react-router";
import "./App.css";
import Chat from "./widgets/Chat";

function App() {
  const [isMobile, setIsMobile] = useState(false);
  useEffect(() => {
    // Load external stylesheets and scripts
    const loadExternalResources = () => {
      const fontAwesomeCdn = document.createElement("link");
      fontAwesomeCdn.rel = "stylesheet";
      fontAwesomeCdn.href =
        "https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.6.0/css/all.min.css";
      fontAwesomeCdn.integrity =
        "sha512-Kc323vGBEqzTmouAECnVceyQqyqdsSiqLQISBL29aUW4U/M7pSPA/gEUZQqv1cwx4OnYxTxve5UMg5GT6L4JJg==";
      fontAwesomeCdn.crossOrigin = "anonymous";
      fontAwesomeCdn.referrerPolicy = "no-referrer";
      document.head.appendChild(fontAwesomeCdn);

      const linkifyJs = document.createElement("script");
      linkifyJs.src =
        "https://cdn.jsdelivr.net/npm/linkifyjs@4.1.3/dist/linkify.min.js";
      document.head.appendChild(linkifyJs);
    };

    loadExternalResources();
  }, []);

  useEffect(() => {
    const handleParentData = (e: MessageEvent) => {
      debugger;
      setIsMobile(e.data.isMobile);
    };

    window.addEventListener("message", handleParentData);
    return () => window.removeEventListener("message", handleParentData);
  }, []);

  return (
    <div
      className="App"
      style={
        isMobile
          ? {
              height: "100%",
              width: "100%",
            }
          : {}
      }
    >
      <Routes>
        <Route
          path="/widgets/chat"
          element={<Chat startOpen={false} isMobile={isMobile} />}
        />
      </Routes>
    </div>
  );
}

export default App;
