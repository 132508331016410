import { useEffect, useState, useRef } from "react";
import ChatService from "../front-services/ChatService";
import Colors from "../utils/Colors";
import Messages from "../utils/Messages";

export interface ChatWidgetStyle {
  color: string;
  name: string;
  fileUrl: string;
}

const chatService = new ChatService();

const Chat = ({ startOpen = false, isMobile }: any) => {
  const [isOpen, setIsOpen] = useState(startOpen);
  const [messages, setMessages]: any = useState([]);
  const [inputMessage, setInputMessage] = useState("");
  const [assistant, setAssistant]: any = useState({ name: null, id: null });
  const [isDev, setIsDev] = useState(false);
  const [, setIsEditor] = useState(false);
  const chatMessagesRef: any = useRef(null);
  const [isLoading, setIsLoading] = useState(false);
  const [threadId, setThreadId]: any = useState(null);

  const [style, setStyle] = useState<ChatWidgetStyle>({
    color: "#a72eff",
    name: "Nome Assistente",
    fileUrl: "https://plutoniosolutions.com/assets/img/SmallCircle.webp",
  });

  useEffect(() => {
    const updateDimensions = () => {
      const width = isMobile && isOpen ? "100%" : isOpen ? 500 : 100;
      const height = isMobile && isOpen ? "100%" : isOpen ? 700 : 100;

      // Send the dimensions to the parent window
      window.parent.postMessage({ width, height }, "*");
    };

    updateDimensions();
    window.addEventListener("resize", updateDimensions);

    return () => {
      window.removeEventListener("resize", updateDimensions);
    };
  }, [isOpen, isMobile]);

  useEffect(() => {
    function handleMessage(event: any) {
      // Security check: Ensure the message is from the expected origin
      /* if (event.origin !== "https://your-parent-app-domain.com") {
        return;
      } */

      const data = event.data as ChatWidgetStyle;

      if (data.color) setStyle(data);
    }

    window.addEventListener("message", handleMessage);

    return () => {
      window.removeEventListener("message", handleMessage);
    };
  }, []);

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const assistant_id = urlParams.get("a_id");
    const assistant_name = urlParams.get("a_name");
    let dev: any = urlParams.get("dev");

    const isEditor = urlParams.get("editor");

    if (dev) {
      setIsDev(true);
      dev = true;
    } else dev = false;

    if (isEditor) {
      setIsEditor(true);
      setIsOpen(true);

      return;
    }

    if (assistant_id) {
      setAssistant({ name: assistant_name, id: assistant_id });

      chatService.getConfiguration(assistant_id, dev).then((response) => {
        if (response.ok) {
          response.json().then((data) => {
            const resStyle = data.configuration.style;
            setStyle({
              color: resStyle.mainColor,
              name: resStyle.name,
              fileUrl: resStyle.fileUrl,
            });
          });
        }
      });
    }
  }, []);

  useEffect(() => {
    // Scroll to the bottom of the chat messages when a new message is added
    chatMessagesRef.current?.scrollTo(0, chatMessagesRef.current.scrollHeight);
  }, [messages]);

  const toggleChatWidget = () => {
    setIsOpen(!isOpen);
  };

  const handleInputChange = (event: any) => {
    setInputMessage(event.target.value);
  };

  const handleUserMessage = async () => {
    if (inputMessage.trim() === "") return;

    setMessages((prevMessages: any) => [
      ...prevMessages,
      { type: "user", content: inputMessage },
    ]);

    setInputMessage("");

    const botResponse = await sendMessage(inputMessage);

    if (botResponse) {
      setMessages((prevMessages: any) => [
        ...prevMessages,
        { type: "bot", content: botResponse },
      ]);
    }
  };

  const sendMessage = async (userMessage: any) => {
    setIsLoading(true);

    const response = await chatService.sendMessage(
      userMessage,
      isDev,
      assistant.id,
      threadId
    );

    if (response.ok) {
      const data = await response.json();
      setIsLoading(false);
      setThreadId(data.threadId);
      return data.messageReply;
    }
    setIsLoading(false);

    return "Sorry, something went wrong. Please try again later.";
  };

  return (
    <>
      {!(isMobile && isOpen) && (
        <div
          className="widget-chat-button p-1 w-14 h-14 rounded-full shadow-lg flex items-center justify-center text-white text-3xl focus:outline-none hover:bg-blue-600 transition-colors duration-200 ease-in-out"
          style={{
            backgroundColor: style.color,
            boxShadow: `0 4px 6px -1px ${style.color}, 0 2px 4px -1px ${style.color}`,
          }}
          onClick={toggleChatWidget}
        >
          <i className="fas fa-comments" style={{ fontSize: "25px" }}></i>
        </div>
      )}

      <div
        style={{
          position: "fixed",
          bottom: isMobile ? "0px" : "100px",
          right: isMobile ? "0px" : "20px",
          width: isMobile ? "100%" : "400px",
          height: isMobile ? "100%" : "600px",
          boxShadow: "1px 4px 4px 1px #f0f0f0, 0 2px 4px -1px #f0f0f0",
          padding: "0px",
          backgroundColor: "#fff",
          display: "flex",
          flexDirection: "column",
          border: "transparent",
          borderRadius: isMobile ? "0px" : "12px",
          overflow: "hidden",
          opacity: !isOpen ? "0" : "1",
          //     transition: "all .2s",
          visibility: !isOpen ? "hidden" : "visible",
        }}
      >
        <div
          className="widget-header"
          style={{ backgroundColor: style.color, borderColor: style.color }}
        >
          <img
            src={style.fileUrl}
            style={{ borderRadius: "50%" }}
            alt="Assistant Icon"
            className="header-icon inline-block"
          />
          <h3 className="text-lg font-semibold ml-2 widget-header-title">
            {style.name}
          </h3>
          <button className="minimize-button" onClick={toggleChatWidget}>
            <i className="fa-solid fa-window-minimize"></i>
          </button>
        </div>

        <div className="widget-chat-messages" ref={chatMessagesRef}>
          {messages.map((message: any, index: any) => (
            <div
              key={index}
              style={{
                marginBottom: "10px",
                backgroundColor:
                  message.type !== "user"
                    ? Colors.lightenHexColor(style.color, 90)
                    : "",
              }}
              className={
                message.type === "user"
                  ? "widget-user-message"
                  : "widget-chatbot-message"
              }
              dangerouslySetInnerHTML={{
                __html: Messages.formatMessage(message.content),
              }}
            ></div>
          ))}
          {isLoading && (
            <div
              className="widget-chatbot-message"
              style={{
                backgroundColor: Colors.lightenHexColor(style.color, 90),
              }}
            >
              <span
                className="loader-container"
                style={{
                  backgroundColor: Colors.lightenHexColor(style.color, 90),
                }}
              >
                <div className="dot"></div>
                <div className="dot"></div>
                <div className="dot"></div>
              </span>
            </div>
          )}
        </div>

        <div className="widget-chat-footer">
          <div className="widget-chat-input-container items-center border border-grey-400">
            <textarea
              className="widget-chat-input"
              placeholder="Type a message..."
              value={inputMessage}
              onChange={handleInputChange}
              onKeyDown={(event) => {
                if (event.key === "Enter") {
                  event.preventDefault();
                  handleUserMessage();
                }
              }}
            />
            <button
              className="widget-send-button flex-none m-0 p-0 text-white"
              style={{ backgroundColor: style.color }}
              onClick={handleUserMessage}
            >
              <i
                className="fa-regular fa-paper-plane"
                style={{ color: "whitesmoke", fontSize: "16px" }}
              ></i>
            </button>
          </div>
          <div className="powered-by">
            <span style={{ fontSize: "8px" }}>
              Powered By{" "}
              <a
                href="https://plutoniosolutions.com/"
                target="_blank"
                rel="noreferrer"
                style={{ fontSize: "12px" }}
              >
                PlutoniosSolutions
                <img
                  src="https://plutoniosolutions.com/assets/img/SmallCircle.webp"
                  alt="Plutonios Solutions Logo"
                  className="inline-block mr-1"
                  style={{
                    width: "10px",
                    verticalAlign: "baseline",
                  }}
                />
              </a>
            </span>
          </div>
        </div>
      </div>
    </>
  );
};

export default Chat;
