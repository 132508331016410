const formatMessage = (message: string) => {
  message = message.replace(/\. /g, ".<br />");
  message = message.replace(/! /g, "!<br />");
  message = message.replace(/\? /g, "?<br />");
  message = message.replace(/: /g, ":<br />");
  return message;
};

const util = { formatMessage };

export default util;
