export default class ChatService {
  public async sendMessage(
    userMessage: any,
    isDev: boolean,
    assistantId: any,
    thread_id: any
  ) {
    const url = isDev
      ? process.env.REACT_APP_API_DEV
      : process.env.REACT_APP_API_PROD;

    return await fetch(`${url}v1/external/assistant/sendMessage`, {
      method: "POST",
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify({
        assistantId,
        message: userMessage,
        thread_id: thread_id ?? null,
      }),
    });
  }

  public async getConfiguration(drastikAssistantId: any, isDev: boolean) {
    const url = `${
      isDev ? process.env.REACT_APP_API_DEV : process.env.REACT_APP_API_PROD
    }${
      process.env.REACT_APP_GET_WIDGET_CHAT_CONFIGURATION
    }?drastikAssistantId=${drastikAssistantId}`;

    return await fetch(`${url}`, {
      method: "GET",
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });
  }
}
